'use client';
import { Button } from '@/components/ui';
import { manrope } from '@/styles/fonts';
import { useRouter } from 'next/navigation';

export default function NotFound() {
  const router = useRouter();

  const backToHome = () => {
    router.push('/');
  };

  return (
    <html lang="en" className={`${manrope.variable} font-sans`}>
      <body>
        <section className="h-screen bg-blue-50">
          <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
            <div className="mx-auto max-w-screen-sm text-center">
              <h1 className="mb-4 text-7xl font-extrabold tracking-tight text-gray-700 lg:text-9xl">404</h1>
              <p className="mb-4 text-3xl font-bold tracking-tight text-primary md:text-4xl">Something's missing.</p>
              <p className="mb-4 text-lg font-light text-primary">
                Sorry, we can't find that page. You'll find lots to explore on the home page.{' '}
              </p>
              <Button className='mt-6' size="sm" onClick={backToHome}>
                Back to Home
              </Button>
            </div>
          </div>
        </section>
      </body>
    </html>
  );
}
